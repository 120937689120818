import React from 'react';
import { graphql } from 'gatsby';

// Types
import { TContentfulPage } from '../utils/types';

// Contentful
import SectionBuilder from '../contentful/SectionBuilder';

// Organisms
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';

const Page: React.FC<TContentfulPage> = (props) => {
	const { data: { contentfulPage } } = props;
	return <main>{SectionBuilder(contentfulPage.sections)}</main>;
};

export default Page;

export const Head: React.FC<TContentfulPage> = (props) => {
	const { data: { contentfulPage } } = props;
	return <HeadMeta meta={contentfulPage.meta || {}} />;
};

export const pageQuery = graphql`
    query PageQuery($slug: String!) {
        contentfulPage(slug: {eq: $slug}) {
            ...PageFields
        }
    }
`;
